import { IconsEnum } from '../../../../../../../assets/icons/types';

import {
  FetchUserMenuQueryResponse,
  FETCH_USER_MENU_QUERY
} from '../../../../../../users/queries/fetchUserMenu.query';

import { useCurrentUser } from '../../../../../../../auth/hooks/useAuth';
import { useUser } from '../../../../../../users/hooks/useUser';

import { NextPureIconBadgeLinkHelper } from '../../../../../../../helpers/links/NextPureIconBadgeLinkHelper';

import { UserCache } from '../../../../../../users/UserCache';
import { UserPath } from '../../../../../../users/UserPath';
import { profilesKeys } from '../../../../../../../locales/keys';

function UserFinanceDebitBadgeLink() {
  const currentUser = useCurrentUser();

  const { user } = useUser<FetchUserMenuQueryResponse>({
    cacheKey: UserCache.showMenuCacheKey(),
    query: FETCH_USER_MENU_QUERY,
    uuid: currentUser.nanoId
  });

  if (!user) {
    return null;
  }

  const profileBalance = user.client ? user.clientBalance : user.workerBalance;

  return (
    <NextPureIconBadgeLinkHelper
      className="focus:outline-none hover:bg-opacity-15 hover:bg-gray-500 px-2.5 align-middle hover:text-black dark:hover:text-white hidden sm:flex items-center gap-1.5 mb-px"
      i18nText={profilesKeys.balance}
      icon={IconsEnum.CASH_OUTLINE}
      iconClassName="w-3.5 h-3.5"
      badgeValue={profileBalance}
      badgePrefix="$"
      href={UserPath.financeDebit()}
    />
  );
}

export default UserFinanceDebitBadgeLink;
