import { useQuery, useQueryClient } from 'react-query';
import { LocalForage } from '../../../../utils/LocalForage';

import { DownloadCache } from '../../DownloadCache';

const downloadManagerBlockCacheKey = 'download-manager-block-opened';

function useDownloadManagerBlockState() {
  const queryClient = useQueryClient();

  const { data: isOpened } = useQuery<boolean>(
    downloadManagerBlockCacheKey,
    () => LocalForage.getItem<boolean>(downloadManagerBlockCacheKey) || false
  );

  const handleOpenDownloadManagerBlock = () => {
    LocalForage.setItem<boolean>(downloadManagerBlockCacheKey, true);
    queryClient.setQueryData<boolean>(downloadManagerBlockCacheKey, true);

    return queryClient.invalidateQueries(DownloadCache.indexCacheKey());
  };

  const handleCloseDownloadManagerBlock = () => {
    LocalForage.setItem<boolean>(downloadManagerBlockCacheKey, false);
    queryClient.setQueryData<boolean>(downloadManagerBlockCacheKey, false);
  };

  const handleToggleDownloadManagerBlock = () => {
    LocalForage.setItem<boolean>(downloadManagerBlockCacheKey, !isOpened);
    queryClient.setQueryData<boolean>(downloadManagerBlockCacheKey, !isOpened);
  };

  return {
    downloadManagerBlockOpened: isOpened,
    openDownloadManagerBlock: handleOpenDownloadManagerBlock,
    closeDownloadManagerBlock: handleCloseDownloadManagerBlock,
    toggleDownloadMangerBlock: handleToggleDownloadManagerBlock
  };
}

export default useDownloadManagerBlockState;
