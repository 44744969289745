import React from 'react';

import { IconsEnum } from '../../../../../assets/icons/types';

import { DownloadManagerBlockItems } from './components/DownloadManagerBlockItems';

import { useDownloadManagerBlock } from './hooks/useDownloadManagerBlock';

import { PureIconButtonHelper } from '../../../../../helpers/buttons/PureIconButtonHelper';
import { Translate } from '../../../../../helpers/Translate';

import { downloadsKeys } from '../../../../../locales/keys';

function DownloadManagerBlock() {
  const {
    downloadManagerBlockOpened,
    downloads,
    downloadsFetched,
    downloadsError,
    totalProgress,
    toggleDownloadMangerBlock,
    closeDownloadManagerBlock
  } = useDownloadManagerBlock();

  if (!downloadsFetched) {
    return null;
  }

  return (
    <div className="w-full relative bg-white dark:bg-gray-900">
      {downloadManagerBlockOpened && (
        <div className="z-0 absolute top-0 right-1/2 pointer-events-none">
          <div className="absolute bottom-0 right-0 flex max-h-screen pt-20 sm:pt-16 w-screen sm:max-w-xl sm:px-2 sm:pb-1.5 transform translate-x-1/2">
            <div className="flex w-full flex-col bg-white dark:bg-gray-850 rounded-t-3xl sm:rounded-b-3xl border dark:border-gray-800 border-b-0 sm:border-b shadow-t-lg overflow-hidden pointer-events-auto">
              <div className="flex-shrink px-4">
                <div className="flex justify-between items-center border-b border-gray-300 dark:border-gray-700 py-3">
                  <h3 className="text-base sm:text-lg text-center flex-1 truncate pl-8">
                    <Translate id={downloadsKeys.plural} />
                  </h3>
                  <div className="-mr-2 -my-2 ml-2">
                    <PureIconButtonHelper
                      className="py-2 pl-2 pr-2 rounded-full inline-flex items-center whitespace-nowrap text-sm font-medium leading-6 focus:ring-base hover:text-gray-950 dark:hover:text-white hover:bg-gray-200 dark:hover:bg-gray-700 focus:ring-offset-0"
                      icon={IconsEnum.CHEVRON_DOWN}
                      onClick={closeDownloadManagerBlock}
                    />
                  </div>
                </div>
              </div>
              <DownloadManagerBlockItems
                downloads={downloads}
                downloadsFetched={downloadsFetched}
                downloadsError={downloadsError}
              />
            </div>
          </div>
        </div>
      )}
      {totalProgress > 0 ? (
        <div className="relative text-xs flex gap-2 border-t border-gray-200 dark:border-gray-700 bg-blue-900 bg-opacity-15 dark:bg-gray-800">
          <div className="absolute inset-0 pointer-events-none">
            <div
              className="h-full bg-stripes animate-bg-to-r"
              style={{ width: `${totalProgress}%` }}
            />
          </div>
          <PureIconButtonHelper
            className="relative focus:ring-none border-2 border-transparent focus:border-black dark:focus:border-white rounded-md flex flex-1 justify-center items-center gap-2"
            icon={IconsEnum.DOWNLOAD_OUTLINE}
            i18nText={downloadsKeys.preparing}
            onClick={toggleDownloadMangerBlock}
          />
          <PureIconButtonHelper
            className="relative focus:ring-none border-2 border-transparent focus:border-black dark:focus:border-white rounded-md flex-shrink-0"
            icon={IconsEnum.X}
            onClick={closeDownloadManagerBlock}
          />
        </div>
      ) : null}
    </div>
  );
}

export default DownloadManagerBlock;
