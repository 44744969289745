import { UserFinanceDebitBadgeLink } from './components/UserFinanceDebitBadgeLink';

function DefaultFooter() {
  return (
    <div className="relative -z-1 w-full border-t border-gray-200 dark:border-gray-700 text-xs leading-6 flex justify-between text-gray-700 dark:text-gray-300 -mb-px">
      <div className="flex">
        <UserFinanceDebitBadgeLink />
      </div>
    </div>
  );
}

export default DefaultFooter;
