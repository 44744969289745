import { useState } from 'react';
import includes from 'lodash/includes';
import filter from 'lodash/filter';
import size from 'lodash/size';
import sumBy from 'lodash/sumBy';

import { DownloadStatuses, DownloadUUID } from '../../../../../downloadsTypes';

import {
  FETCH_DOWNLOADS_QUERY,
  FetchDownloadsQueryResponse
} from '../../../../../queries/fetchDownloads.query';

import { usePaginatedDownloads } from '../../../../../hooks/usePaginatedDownloads';
import { useDownloadNanoId } from '../../../../../hooks/useDownloadNanoId';
import { useDownloadManagerBlockState } from '../../../../../hooks/useDownloadManagerBlockState';

import { downloadFile } from '../../../../../../../utils/downloadFile';

import { DownloadCache } from '../../../../../DownloadCache';

const downloadedUuids: DownloadUUID[] = [];

function useDownloadManagerBlock() {
  const {
    downloadManagerBlockOpened,
    openDownloadManagerBlock,
    closeDownloadManagerBlock,
    toggleDownloadMangerBlock
  } = useDownloadManagerBlockState();

  const [processingDownloadsCount, setProcessingDownloadsCount] =
    useState<number>(0);

  const { downloadNanoId } = useDownloadNanoId();

  const { downloads, downloadsFetched, downloadsError } =
    usePaginatedDownloads<FetchDownloadsQueryResponse>({
      query: FETCH_DOWNLOADS_QUERY,
      cacheKey: DownloadCache.indexCacheKey(),
      initialFilters: {
        self: true,
        status: { notEq: DownloadStatuses.FAILED }
      },
      initialLimit: 10,
      options: {
        refetchInterval: 1000 * 10,
        onSuccess: async (data) => {
          const processingDownloads = filter(
            data?.downloads?.nodes || [],
            (download) =>
              download.status === DownloadStatuses.INITIALIZED ||
              download.status === DownloadStatuses.PROCESSING
          );

          data?.downloads?.nodes?.forEach((download) => {
            if (
              downloadNanoId === download.deviceNanoId &&
              download.status === DownloadStatuses.COMPLETED &&
              !includes<DownloadUUID>(downloadedUuids, download.uuid)
            ) {
              downloadedUuids.push(download.uuid);
              console.log('DownloadManagerBlock', {
                downloadedUuids,
                downloadNanoId,
                deviceNanoId: download.deviceNanoId
              });
              downloadFile(download.file);
            }
          });

          if (size(processingDownloads) !== processingDownloadsCount) {
            setProcessingDownloadsCount(size(processingDownloads));
          }
        }
      }
    });

  const a = sumBy(downloads, (download) =>
    download.status === DownloadStatuses.COMPLETED ? 0 : download.progress
  );

  const b = size(
    filter(
      downloads,
      (download) => download.status !== DownloadStatuses.COMPLETED
    )
  );

  const totalProgress = b === 0 ? 0 : a / b;

  return {
    downloadManagerBlockOpened,
    downloads,
    downloadsFetched,
    downloadsError,
    totalProgress,
    closeDownloadManagerBlock,
    openDownloadManagerBlock,
    toggleDownloadMangerBlock
  };
}

export default useDownloadManagerBlock;
